import { formatPhoneNumber } from "@/utils/helpers/formatPhoneNumber";
import { useContacts } from "@/utils/hooks/useContacts";
import VerseLogo from "../../assets/VerseLogo";
import { name } from "../../components/contactListScreen/ContactCard.style";
import { colors } from "../../styles/global.styles";
import WebGwContact from "../../utils/helpers/WebGwContact";
import { Avatar } from "../shared/Avatar";
import { listItemStyle } from "../shared/ListItem.style";
import { logo } from "./ContactCard.style";

import { useContactPhoneNumbersWithTypesAndCaps } from "@/utils/hooks/useContactPhoneNumbers";
import { CircularProgress } from "@mui/material";
type Props = React.ComponentProps<"div"> & {
  contact: WebGwContact;
  searchIndices?: NonNullable<
    ReturnType<(typeof WebGwContact)["prototype"]["filterContact"]>
  >[1];
  searchQueryLength: number;
  disabled?: boolean;
};

function ContactCard({
  contact: receivedContact,
  searchIndices,
  searchQueryLength,
  disabled,
  ...liProps
}: Props) {
  const contacts = useContacts();
  const contact =
    contacts?.find((pContact) => pContact.id === receivedContact.id) ??
    receivedContact;

  const numberToUse = contact.getMainPhoneNumber();

  const mainPhoneNumberFormatted = searchQueryLength
    ? numberToUse
    : formatPhoneNumber(numberToUse, "E123");

  const contactName = contact.noNameReturnPhoneNumber(
    mainPhoneNumberFormatted || numberToUse
  );

  const noName = !contactName;

  return (
    <div
      {...liProps}
      css={listItemStyle}
      style={{ ...(disabled && { cursor: "default" }) }}
    >
      <Avatar contact={contact} />
      <div>
        <div css={name} style={{ ...(noName && { fontStyle: "italic" }) }}>
          {noName ? "No Name" : contactName}
        </div>
        <div
          css={[
            name,
            {
              color: colors.secondaryTextColor,
              marginTop: "0.25em",
              fontSize: "0.8em",
            },
          ]}
        >
          {mainPhoneNumberFormatted}
        </div>
      </div>

      <div
        css={{
          width: "100%",
          height: "2em",
          display: "flex",
          justifyContent: "end",
        }}
      >
        <div css={logo}>
          <VerseCardLogo contact={contact} />
        </div>
      </div>
    </div>
  );
}

function VerseCardLogo({ contact }: { contact: WebGwContact }) {
  const { isLoading } = useContactPhoneNumbersWithTypesAndCaps(contact.id);
  return contact.isVerse ? (
    <VerseLogo />
  ) : isLoading ? (
    <CircularProgress color="error" css={logo} />
  ) : null;
}

export default ContactCard;
