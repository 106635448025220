import { isValidPhoneNumber } from "@/utils";
import WebGwContact from "@/utils/helpers/WebGwContact";
import { NumberWithType } from "@/utils/helpers/WebGwContactUtils";
import { getLocalUser } from "@/utils/helpers/localstorage";
import { useBlock } from "@/utils/hooks/useBlock";
import { useCall } from "@/utils/hooks/useCall";
import { CapabilityType } from "@/utils/hooks/useCapabilities";
import { isSamePhoneNumber } from "@/utils/messaging/conversation/conversationUtils/phoneNumberUtils";
import MessageIcon from "@mui/icons-material/Message";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import PhoneIcon from "@mui/icons-material/Phone";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import VideocamIcon from "@mui/icons-material/Videocam";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import ContactInfoDropdown from "../shared/ContactInfoDropdown";
import { BlockedContact } from "./BlockedContact";
import {
  callOptions,
  callOptionsIcon1,
  callOptionsIcon2,
  logo,
} from "./ContactInfo.style";

const localUserPhoneNumber = getLocalUser();

const GenericAction = ({
  phoneNumber,
  action,
  icon,
  capabilityType,
  canAcceptOrMakeCall,
}: {
  phoneNumber?: string;
  action: (phoneNumber: string) => void;
  icon: any;
  capabilityType: CapabilityType;
  canAcceptOrMakeCall: boolean;
}) => {
  const isMyself = isSamePhoneNumber(localUserPhoneNumber, phoneNumber);

  // Sometimes contacts with no number have their unique phone number using the contact name
  // In order to not change this logic, we check here that the phone number is a real number
  // Messaging always enabled, calling only if not for myself
  const canMakeAction =
    phoneNumber &&
    isValidPhoneNumber(phoneNumber) &&
    (capabilityType === CapabilityType.MESSAGING ||
      (!isMyself && canAcceptOrMakeCall));

  return (
    <button
      type="button"
      css={logo}
      onClick={() => (phoneNumber ? action(phoneNumber) : {})}
      disabled={!canMakeAction}
    >
      {icon}
    </button>
  );
};

const ContactActionsHeader = ({
  mainPhoneNumber,
  rcsPhoneNumbers,
  onSelectConversation,
  contactBlocked,
  onContactBlocked,
}: {
  mainPhoneNumber: string;
  rcsPhoneNumbers?: NumberWithType[];
  onSelectConversation: (phoneNumber: string) => void;
  contactBlocked?: boolean;
  onContactBlocked?: (blocked: boolean) => void;
}) => {
  const { callWithAudio, callWithVideo, canAcceptOrMakeCall } = useCall();
  const phoneNumber = rcsPhoneNumbers?.[0]?.[0] || mainPhoneNumber;
  const contact = WebGwContact.fromPhoneNumber(phoneNumber);
  const block = useBlock(contact, contactBlocked, onContactBlocked);

  if (block.value === "Unblock") {
    return (
      <>
        <BlockedContact handleUnblockContact={block.show} contact={contact} />
        {block.modal}
      </>
    );
  }

  /**
   * TODO - right now we dont support multi numbers anymore, but once this will be back, we need to cut the rcsPhoneNumbers in 3 parts:
   * - the ones with voice
   * - same for video
   * - same for messaging
   *
   * Then display them in the select option below
   */
  return rcsPhoneNumbers && rcsPhoneNumbers?.length > 1 ? (
    <div css={callOptions}>
      <div css={logo}>
        <ContactInfoDropdown
          options={rcsPhoneNumbers}
          onSelect={(selectedOption) => {
            callWithAudio(selectedOption[0]);
          }}
          placeholder={<PhoneIcon />}
          icon={<PhoneOutlinedIcon />}
        />
      </div>
      <div css={logo}>
        <ContactInfoDropdown
          options={rcsPhoneNumbers}
          onSelect={([phoneNumber, _type]) => {
            onSelectConversation(phoneNumber);
          }}
          placeholder={<MessageIcon />}
          icon={<MessageOutlinedIcon />}
        />
      </div>
      <div css={logo}>
        <ContactInfoDropdown
          options={rcsPhoneNumbers}
          onSelect={(selectedOption) => callWithVideo(selectedOption[0])}
          placeholder={<VideocamIcon />}
          icon={<VideocamOutlinedIcon />}
        />
      </div>
    </div>
  ) : (
    <div css={callOptions}>
      <GenericAction
        phoneNumber={rcsPhoneNumbers?.[0]?.[0] || mainPhoneNumber}
        capabilityType={CapabilityType.MESSAGING}
        action={onSelectConversation}
        icon={<MessageIcon css={callOptionsIcon1} />}
        canAcceptOrMakeCall={canAcceptOrMakeCall}
      />

      <GenericAction
        phoneNumber={rcsPhoneNumbers?.[0]?.[0] || mainPhoneNumber}
        capabilityType={CapabilityType.VOICE}
        action={callWithAudio}
        icon={<PhoneIcon css={callOptionsIcon1} />}
        canAcceptOrMakeCall={canAcceptOrMakeCall}
      />

      <GenericAction
        phoneNumber={rcsPhoneNumbers?.[0]?.[0] || mainPhoneNumber}
        capabilityType={CapabilityType.VIDEO}
        action={callWithVideo}
        icon={<VideocamIcon css={callOptionsIcon2} />}
        canAcceptOrMakeCall={canAcceptOrMakeCall}
      />
    </div>
  );
};

export default ContactActionsHeader;
