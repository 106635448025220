import VerseLogo from "@/assets/VerseLogo";
import { atoms } from "@/utils/helpers/atoms";
import { formatPhoneNumber } from "@/utils/helpers/formatPhoneNumber";
import { useContactPhoneNumbersWithTypesAndCaps } from "@/utils/hooks/useContactPhoneNumbers";
import { useContacts } from "@/utils/hooks/useContacts";
import Conversation from "@/utils/messaging/conversation/Conversation";
import { setSelectedConversationId } from "@/utils/messaging/conversation/ConversationState";
import { useAtomValue } from "jotai";
import { useNavigate } from "react-router-dom";
import { paths } from "../../routerPaths";
import WebGwContact from "../../utils/helpers/WebGwContact";
import { AvatarBackground, DefaultAvatar } from "../shared/Avatar";
import ContactActionsHeader from "./ContactActionsHeader";
import {
  InfoContainer,
  InfoSection,
  Root,
  avatar,
  companyCss,
  infoDetails,
  infoPhone,
  infoTitle,
  name,
  title,
  type,
} from "./ContactInfo.style";

type ContactInfoProps = {
  webgwContact?: WebGwContact | null;
  contactBlocked: boolean;
  onContactBlocked: (blocked: boolean) => void;
};

const ContactInfo = ({
  contactBlocked,
  onContactBlocked,
}: ContactInfoProps) => {
  const navigate = useNavigate();

  const contactId = useAtomValue(atoms.contacts.displayContactId);
  const contacts = useContacts();
  const contact = contacts?.find((c) => c.id === contactId);

  const { data: rcsPhoneNumbers } = useContactPhoneNumbersWithTypesAndCaps(
    contact?.id
  );

  if (!contact || !contact.id) {
    return null;
  }

  const initials = contact.initials!;

  const contactName = contact.noNameReturnPhoneNumber(
    contact.getMainPhoneNumber()
  );

  const header =
    initials?.length > 0 ? (
      <div css={title}>
        <div css={{ padding: "2em 0 1em" }}>
          <AvatarBackground css={avatar}>
            <span>{contact.initials}</span>
          </AvatarBackground>
        </div>
        <div css={name}>{contactName}</div>
        {contact.company && <div css={companyCss}>{contact.company}</div>}
      </div>
    ) : (
      <div css={title}>
        <div css={{ padding: "2em 0 1em" }}>
          <AvatarBackground css={avatar}>
            <span>
              <DefaultAvatar />
            </span>
          </AvatarBackground>
        </div>
        <div css={name}>{contactName}</div>
        {contact.company && <div css={companyCss}>{contact.company}</div>}
      </div>
    );

  const formatAddress = (address: string[]) => {
    return address
      .slice(1)
      .slice(0, 6)
      .filter((current) => current)
      .join(", ");
  };

  const handleSelectConversation = (phoneNumber: string | null) => {
    if (!phoneNumber) {
      return;
    }

    setSelectedConversationId(
      Conversation.getOrCreate({ phoneNumber, contactToLinkIfCreate: contact })
        .conversation.id
    );
    navigate(paths.messages);
  };

  const checkIfNumberHasCaps = (numberToCheck: string) => {
    const foundItem =
      rcsPhoneNumbers &&
      rcsPhoneNumbers.find((item) => item[0] === numberToCheck);

    if (foundItem) {
      return foundItem[2];
    }
  };

  return (
    <Root>
      {header}

      <ContactActionsHeader
        mainPhoneNumber={contact.getMainPhoneNumber()}
        rcsPhoneNumbers={rcsPhoneNumbers}
        onSelectConversation={handleSelectConversation}
        contactBlocked={contactBlocked}
        onContactBlocked={onContactBlocked}
      />

      <InfoContainer
        css={{
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <InfoSection>
          <div css={infoTitle}>PHONE</div>
          {contact["phone; mobile"] && contact["phone; mobile"].length > 0 && (
            <>
              <div css={type}>Mobile</div>
              <div css={infoPhone}>
                {contact["phone; mobile"]?.map((number, index) => (
                  <div css={infoDetails} key={index}>
                    {formatPhoneNumber(number[0], "E123")}
                    {checkIfNumberHasCaps(number[0]) && (
                      <VerseLogo
                        css={{
                          height: "1em",
                        }}
                      />
                    )}
                  </div>
                ))}
              </div>
            </>
          )}

          {contact["phone; work"] && contact["phone; work"].length > 0 && (
            <>
              <div css={type}>Work</div>
              <div css={infoPhone}>
                {contact["phone; work"]?.map((number, index) => (
                  <div css={infoDetails} key={index}>
                    {formatPhoneNumber(number[0], "E123")}
                    {checkIfNumberHasCaps(number[0]) && (
                      <VerseLogo css={{ height: "1em" }} />
                    )}
                  </div>
                ))}
              </div>
            </>
          )}

          {contact["phone; home"] && contact["phone; home"].length > 0 && (
            <>
              <div css={type}>Home</div>
              <div css={infoPhone}>
                {contact["phone; home"]?.map((number, index) => (
                  <div css={infoDetails} key={index}>
                    {formatPhoneNumber(number[0], "E123")}
                    {checkIfNumberHasCaps(number[0]) && (
                      <VerseLogo css={{ height: "1em" }} />
                    )}
                  </div>
                ))}
              </div>
            </>
          )}

          {contact["phone"] && contact["phone"][0].length > 0 && (
            <>
              <div css={type}>Other</div>
              <div css={infoPhone}>
                {contact["phone"]?.map((number, index) => (
                  <div css={infoDetails} key={index}>
                    {formatPhoneNumber(number[0], "E123")}
                    {checkIfNumberHasCaps(number[0]) && (
                      <VerseLogo css={{ height: "1em" }} />
                    )}
                  </div>
                ))}
              </div>
            </>
          )}
        </InfoSection>

        <InfoSection>
          <div css={infoTitle}>EMAIL</div>
          {contact["email; work"] && contact["email; work"].length > 0 && (
            <>
              <div css={type}>Work</div>

              {contact["email; work"]?.map((email, index) => (
                <div css={infoDetails} key={index}>
                  {email[0]}
                </div>
              ))}
            </>
          )}

          {contact["email; home"] && contact["email; home"].length > 0 && (
            <>
              <div css={type}>Home</div>
              {contact["email; home"]?.map((email, index) => (
                <div css={infoDetails} key={index}>
                  {email[0]}
                </div>
              ))}
            </>
          )}

          {contact["email"] && contact["email"][0].length > 0 && (
            <>
              <div css={type}>Other</div>

              {contact["email"]?.map((email, index) => (
                <div css={infoDetails} key={index}>
                  {email[0]}
                </div>
              ))}
            </>
          )}
        </InfoSection>

        {contact.url && (
          <InfoSection>
            <div css={infoTitle}>WEBSITE</div>
            <div css={type} />
            <div css={infoDetails}>{contact.url}</div>
          </InfoSection>
        )}

        <InfoSection>
          <div css={infoTitle}>ADDRESS</div>
          {contact["address; home"] && contact["address; home"].length > 0 && (
            <>
              <div css={type}>Home</div>
              <div>
                {contact["address; home"].map((address, index) => (
                  <div css={infoDetails} key={index}>
                    {formatAddress(address)}
                  </div>
                ))}
              </div>
            </>
          )}
          {contact["address; work"] &&
            contact["address; work"][0].length > 0 && (
              <>
                <div css={type}>Work</div>
                <div>
                  {contact["address; work"].map((address, index) => (
                    <div css={infoDetails} key={index}>
                      {formatAddress(address)}
                    </div>
                  ))}
                </div>
              </>
            )}

          {contact["address"] && contact["address"][0].length > 7 && (
            <>
              <div css={type}>Other</div>
              <div>
                {contact["address"].map((address, index) => (
                  <div css={infoDetails} key={index}>
                    {formatAddress(address)}
                  </div>
                ))}
              </div>
            </>
          )}
        </InfoSection>

        {contact.birthday && contact.birthday !== "null" && (
          <InfoSection>
            <div css={infoTitle}>BIRTHDAY</div>
            <div css={type} />
            <div css={infoDetails}>{contact.birthday}</div>
          </InfoSection>
        )}

        {contact.note && (
          <InfoSection>
            <div css={infoTitle}>NOTE</div>
            <div css={type} />
            <div css={infoDetails}>{contact.note}</div>
          </InfoSection>
        )}

        <InfoSection>
          <div css={infoTitle}>RELATED</div>
          {contact["person; Mother"] &&
            contact["person; Mother"].length > 0 && (
              <>
                <div css={type}>Mother</div>

                {contact["person; Mother"]?.map((person, index) => (
                  <div css={infoDetails} key={index}>
                    {person[0]}
                  </div>
                ))}
              </>
            )}

          {contact["person; Father"] &&
            contact["person; Father"].length > 0 && (
              <>
                <div css={type}>Father</div>
                {contact["person; Father"]?.map((person, index) => (
                  <div css={infoDetails} key={index}>
                    {person[0]}
                  </div>
                ))}
              </>
            )}

          {contact["person; Sibling"] &&
            contact["person; Sibling"][0].length > 0 && (
              <>
                <div css={type}>Sibling</div>

                {contact["person; Sibling"]?.map((person, index) => (
                  <div css={infoDetails} key={index}>
                    {person[0]}
                  </div>
                ))}
              </>
            )}

          {contact["person; Family"] &&
            contact["person; Family"].length > 0 && (
              <>
                <div css={type}>Family</div>

                {contact["person; Family"]?.map((person, index) => (
                  <div css={infoDetails} key={index}>
                    {person[0]}
                  </div>
                ))}
              </>
            )}

          {contact["person; Friend"] &&
            contact["person; Friend"].length > 0 && (
              <>
                <div css={type}>Friend</div>
                {contact["person; Friend"]?.map((person, index) => (
                  <div css={infoDetails} key={index}>
                    {person[0]}
                  </div>
                ))}
              </>
            )}

          {contact["person; Other"] &&
            contact["person; Other"][0].length > 0 && (
              <>
                <div css={type}>Other</div>

                {contact["person; Other"]?.map((person, index) => (
                  <div css={infoDetails} key={index}>
                    {person[0]}
                  </div>
                ))}
              </>
            )}

          {contact["person"] && contact["person"][0].length > 0 && (
            <>
              <div css={type} />

              {contact["person"]?.map((person, index) => (
                <div css={infoDetails} key={index}>
                  {person[0]}
                </div>
              ))}
            </>
          )}
        </InfoSection>
      </InfoContainer>
    </Root>
  );
};

export default ContactInfo;
